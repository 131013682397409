import React from "react"
import CreatureData from "./components/creature-data"
import { Transition } from "@headlessui/react"
// import Image from "gatsby-image"
import Image from "gatsby-plugin-sanity-image"
import { Modal } from "react-responsive-modal"
import BlockContent from "@sanity/block-content-to-react"
import { serializers } from "../../../shared/serializers"

const CommonCreaturePage = ({ data }) => {
  const [expanded, setExpanded] = React.useState(null)
  const [showModalName, setShowModalName] = React.useState(-1)

  const onCloseModal = () => setShowModalName(-1)

  const closeAll = index => {
    if (index == expanded) {
      setExpanded(null)
    } else {
      setExpanded(index)
    }
  }

  if (data == undefined) {
    return <></>
  } else {
    const creatures = data.creature.nodes
    const strAscending = [...creatures].sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
    // console.log(strAscending, "strAscending")
    const denizen_name = data.denizen.name

    return (
      <>
        {creatures.length > 0 && (
          <div className="mt-6">
            <div className="flex flex-col">
              <div className="mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 creatureTable">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50 bcg">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider title-td wc"
                            style={{ textAlign: "center" }}
                          >
                            Name
                          </th>

                          {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Size
                        </th> */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider wc"
                            style={{ textAlign: "center" }}
                          >
                            SUMMARY
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="bg-white divide-y divide-gray-200 bcg">
                        {strAscending.map((creature, index) => (
                          <>
                            <tr className="hover:bg-orange-50 cursor-pointer even-bgc bcg">
                              <td className="px-6 py-4 whitespace-nowrap title-td">
                                <div className="flex items-center">
                                  <div
                                    className="denizens-bio"
                                    onClick={() => setShowModalName(index)}
                                  >
                                    <Image
                                      {...creature.image}
                                      className="h-21 w-15 rounded-full border border-gray-200 shadow-sm"
                                      style={{
                                        objectFit: "cover",
                                        minWidth: "90px",
                                        height: "94px",
                                      }}
                                      alt={`${creature.name} Avatar`}
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div
                                      className="text-sm font-medium text-gray-900 wc"
                                      onClick={() => setShowModalName(index)}
                                    >
                                      {creature.name}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {/* Infernal machine rebuild */}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 wc"
                                onClick={() => closeAll(index)}
                              >
                                {/* {creature._rawFlavorText[0].children[0].text} */}
                                {creature.summary_flavor_text}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm md:text-base font-medium wc">
                                <button
                                  type="button"
                                  className="text-indigo-600 hover:text-indigo-900"
                                  onClick={() => closeAll(index)}
                                >
                                  More
                                </button>
                              </td>
                            </tr>
                            <Transition
                              as="tr"
                              show={expanded == index ? true : false}
                              className="shadow-inner border-gray-200"
                            >
                              <CreatureData
                                creature={creature}
                                denizen={denizen_name}
                                index={index}
                                denizens={creature.type}
                              />
                            </Transition>
                            <Modal
                              open={showModalName === index ? true : false}
                              onClose={onCloseModal}
                              center
                              styles={{
                                modal: { background: "#333333" },
                                closeIcon: { fill: "white" },
                              }}
                            >
                              <div>
                                <div className="block">
                                  <div className="mt-12 mx-auto px-6 sm:px-0">
                                    <div className="mt-12 popup-header">
                                      <h1 className="titleStyle text-mocha text-5xl-custom font-display font-bold wc">
                                        {creature.name}
                                      </h1>
                                    </div>
                                    {/* <div className="w-80 sm:float-right"> */}
                                    <div className="sm:float-right">
                                      <Image
                                        {...creature.image}
                                        alt={`${creature.name} Avatar`}
                                        className="biograph-image"
                                        style={{
                                          margin: "auto",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>

                                    <div className="max-w-none mx-auto prose popup lg:prose-lg wc">
                                      <BlockContent
                                        blocks={creature._rawFlavorText}
                                        serializers={serializers}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default CommonCreaturePage
