import React from "react"
import { Transition } from "@headlessui/react"
import Image from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"

import { Creature } from "../../../../types/Creature"
import CreatureTable from "../../../../templates/creature/components/creature-table"
import SpeciesTable from "../../../../templates/creature/components/species-table"

const CreatureData: React.FC<{
  creature: Creature
  index: number
  denizen: string
  denizens: { name: string }[]
}> = ({ creature, index, denizen, denizens }) => {
  return (
    <>
      <td
        colSpan={6}
        className="table-cell w-full px-7 py-4 pb-8 whitespace-nowrap"
      >
        <div className="grid gap-12">
          {denizen === "Latter Kin" ? (
            <SpeciesTable creature={creature} />
          ) : (
            <CreatureTable creature={creature} />
          )}
        </div>
      </td>
    </>
  )
}

export default CreatureData
