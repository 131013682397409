import React from "react"
import { Creature } from "../../../types/Creature"
import BlockContent from "@sanity/block-content-to-react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import Image from "gatsby-plugin-sanity-image"
import Layout from "../../../components/layout"
import { serializers } from "../../../shared/serializers"

const SpeciesTable: React.FC<{ creature: Creature }> = ({ creature }) => {
  // console.log("creature species", creature, serializers)
  const [open, setOpen] = React.useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <div
      className="mt-3 md:mt-6 bg-white shadow overflow-hidden sm:rounded-lg"
      style={{ backgroundColor: "#444444" }}
    >
      <div className="px-4 py-3 sm:px-6 flex items-center justify-between border border-gray-200 hover:text-indigo-600">
        <h3
          onClick={onOpenModal}
          className="denizens-bio font-semibold text-gray-900"
        >
          {/* {creature.name} */}
          Biography
        </h3>
        <div className="max-w-2xl text-sm flex items-center text-gray-500">
          <dt className="text-sm font-medium text-gray-500 wc">
            <p style={{ fontSize: "18px" }}>HP:</p>
          </dt>
          <dd
            style={{ fontSize: "18px" }}
            className="ml-1 text-sm text-gray-900 wc"
          >
            {creature.hit_points}
          </dd>
        </div>
      </div>

      <div className="border-t border-gray-200">
        <dl className="grid grid-cols-1 md:grid-cols-9">
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-5 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Size:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.size}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-4 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Speed:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.speed}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-5 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Lifespan:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.lifespan}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-4 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Reach:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.reach}</dd>
          </div>

          <div className="px-4 py-3 md:py-3 overflow-x-hidden sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium  text-gray-500 wc">Ma:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.marksmanship}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">S:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.strength}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">D:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.defense}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">A:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.attack}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">M:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.might}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">W:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.will}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">F:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">{creature.fate}</dd>
          </div>
          <div
            className="px-4 py-3 md:py-3 overflow-x-hidden sm:px-6 sm:col-span-1 flex items-center  border border-gray-200"
            style={{ overflow: creature.stamina?.length > 4 ? "auto" : "" }}
          >
            <dt className="text-sm font-medium text-gray-500 wc">Sta:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.stamina}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Ag:</dt>
            <dd className="ml-1 text-sm text-gray-900 wc">
              {creature.agility}
            </dd>
          </div>
          {/* <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-5 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Cha:</dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.charisma}</dd>
          </div>
          <div className="px-4 py-3 md:py-5 overflow-x-auto sm:px-3 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium  overflow-x-hidden text-gray-500 ">
              Cou:
            </dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.courage}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-3 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium overflow-x-hidden text-gray-500">
              Dex:
            </dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.dexterity}</dd>
          </div> */}
          {/* <div className="px-4 py-3 md:py-3 overflow-x-hidden sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">For:</dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.fortitude}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-5 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Gui:</dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.guile}</dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Int:</dt>
            <dd className="ml-1 text-sm text-gray-900">
              {creature.intelligence}
            </dd>
          </div> */}
          {/* <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-5 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Lor:</dt>
            <dd className="ml-1 text-sm text-gray-900">
              {creature.lore_points}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-hidden sm:px-6 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Per:</dt>
            <dd className="ml-1 text-sm text-gray-900">
              {creature.perception}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-5 sm:col-span-1 flex items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Ste:</dt>
            <dd className="ml-1 text-sm text-gray-900">{creature.stealth}</dd>
          </div> */}

          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-9 flex-col items-center border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Modifiers:</dt>
            <dd className="ml-1 mt-2 prose max-w-none text-sm text-gray-900 wc">
              <BlockContent blocks={creature._rawModifiers} />
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-9 flex-col items-center  border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">
              Special Feat:
            </dt>
            <dd className="ml-1 mt-2 prose max-w-none text-sm text-gray-900 wc">
              {creature.special_feat}
            </dd>
          </div>
          <div className="px-4 py-3 md:py-3 overflow-x-auto sm:px-6 sm:col-span-1 md:col-span-9 flex-col items-center border border-gray-200">
            <dt className="text-sm font-medium text-gray-500 wc">Details:</dt>
            <dd
              className="ml-1 mt-2 max-w-none text-sm text-gray-900 wc"
              style={{ lineHeight: 1.75 }}
            >
              <BlockContent blocks={creature._rawDetails} />
            </dd>
          </div>
        </dl>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{
          modal: { background: "#333333" },
          closeIcon: { fill: "white" },
        }}
      >
        <div>
          <div className="block">
            <div className="mt-12 mx-auto px-6 sm:px-0">
              <div className="mt-12 popup-header">
                <h1 className="titleStyle text-mocha text-5xl-custom font-display font-bold wc">
                  {creature.name}
                </h1>
              </div>
              {/* <div className="w-80 sm:float-right">*/}
              <div className="sm:float-right">
                <Image
                  {...creature.image}
                  alt={`${creature.name} Avatar`}
                  className="biograph-image"
                  style={{
                    margin: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="max-w-none mx-auto prose popup lg:prose-lg wc">
                <BlockContent
                  blocks={creature._rawFlavorText}
                  serializers={serializers}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SpeciesTable
